<template>
  <div>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      color="primary"
      v-if="loading"
      top
    />
    <v-snackbar v-model="snackbar" :color="snackbar_color" rounded="pill" top>
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-row>
        <v-col cols="2">
          <v-btn icon to="/" class="justify-center" color="blue-grey lighten-3">
            <v-icon>fas fa-angle-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" class="d-flex justify-center">
          <h3 class="light-blue--text">Submit your Review</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-img src="../assets/review.jpg" class="rounded-lg" />
      </v-row>
      <v-row justify="center">
        <v-col cols="10" align-self="center">
          <v-form
            @submit.prevent="postReview"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="review.name"
              :rules="nameRules"
              label="Full Name"
              type="text"
            />
            <v-text-field
              v-model="review.contact"
              :rules="contactRules"
              label="Contact Number"
              type="text"
            />
            <v-text-field
              v-model="review.city"
              :rules="cityRules"
              label="City"
              type="text"
            />
            <v-textarea
              :rules="reviewRules"
              label="Post your review"
              v-model="review.reviewText"
              counter="150"
            />
            <v-rating
              v-model="review.rating"
              color="yellow Accent-3"
              half-increments
              size="24"
              class="my-3 d-flex justify-center"
            ></v-rating>
            <v-btn
              color="info"
              type="submit"
              rounded
              block
              @click="validate"
              :disabled="!valid"
            >
              Post your review
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import Property from "../api/Property";
// import ReviewMixin from "../mixins/ReviewMixin";
export default {
  name: "review",
  //   mixins: [ReviewMixin],
  data() {
    return {
      valid: true,
      loading: false,
      snackbar: false,
      text: "",
      snackbar_color: null,
      review: {
        name: "",
        contact: "",
        city: "",
        reviewText: "",
        rating: 0,
      },
      nameRules: [(v) => !!v || "Name is required"],
      cityRules: [(v) => !!v || "City is required"],
      ratingRules: [(v) => !!v || "Rating is required"],
      contactRules: [
        (v) => !!v || "Contact Number is required.",
        (v) => !isNaN(parseInt(v)) || "Contact Number must be digit.",
        (v) =>
          (v && v.length >= 10) || "Contact Number must have atleast 10 digit",
        (v) =>
          (v && v.length <= 10) ||
          "Contact Number exceeds maximum allowed number",
      ],
      reviewRules: [
        (v) => !!v || "Review is required.",
        (v) => (v && v.length <= 150) || "Review must be upto 150 charecter",
      ],
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    clearAllField() {
      this.review.name = "";
      this.review.contact = "";
      this.review.city = "";
      this.review.reviewText = "";
      this.review.rating = 0;
    },
    async postReview() {
      this.loading = true;
      const fd = new FormData();
      fd.append("name", this.review.name);
      fd.append("contact", this.review.contact);
      fd.append("city", this.review.city);
      fd.append("review", this.review.reviewText);
      fd.append("rating", this.review.rating);
      await Property.postReview(fd)
        .then((response) => {
          if (response.status == 201) {
            this.loading = false;
            this.text = response.data.message;
            this.snackbar = true;
            this.snackbar_color = "success";
            //console.log(response.data);
            this.clearAllField();
          }
        })
        .catch(function (error) {
          this.loading = false;
          this.text = error.response.data.message;
          this.snackbar = true;
          this.snackbar_color = "error";
          //console.log("Error message :", error.message);
        });
    },
  },
};
</script>
